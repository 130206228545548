.hiring-companies-sec {
    background-color: #f9f0f5;
    padding-top: 96px;
    padding-bottom: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.company-logo-images {
    width: 150px;
}

.companies-hired {
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 0;
    padding-bottom: 0;
}

.companies-hired-top-div {
    color: #1e293b;
    text-align: center;
    font-family: Kumbh Sans;
    font-size: 35px;
    font-weight: 600;
    line-height: 64px;
}

.companies-hired-top-div-txt-span-01 {
    color: #c76a97;
}


.companies-hired-images {
    max-width: 1200px;
    margin-top: 48px;
    margin-bottom: 32px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.scroll-container {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.scroll-shadow {
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle, rgba(2, 0, 36, 0) 43%, rgba(2, 0, 36, 0) 83%, rgba(2, 0, 36, 0) 100%);
}

.scroll-left {

    white-space: nowrap;
    will-change: transform;
    animation: scroll-left 5s linear infinite;

}

.scroll-right {

    white-space: nowrap;
    will-change: transform;
    animation: scroll-right 5s linear infinite;

}

.scroll-images {

    width: calc(100% * 3);
    /* Adjust to the number of images to fill the container */
}

.scroll-images img {
    flex: 1;
    padding: 1rem 0 0;
    margin: 1rem 10px;
    width: auto;
    height: 63px;
    /* Ensuring consistent height */
}

@keyframes scroll-left {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-33.3333%);
    }
}

@keyframes scroll-right {
    0% {
        transform: translateX(-33.3333%);
    }

    100% {
        transform: translateX(0);
    }
}

.tools-sec {
    background-color: #f9f0f5;
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tools-section-heading {
    color: #1e293b;
    text-align: center;
    font-family: Kumbh Sans;
    font-size: 35px;
    font-weight: 600;
    line-height: 64px;
}

.tools-section-bottom-div {
    max-width: 1200px;
    margin-top: 48px;
    margin-bottom: 32px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
}

.tools-images {
    padding: 10px;
}

.tools-image-individual {
    height: 120px;
    width: 120px;
}

.tools-image-individual-certificate {
    margin-top: 10px;
    background-color: white;
    height: 160px;
    width: 180px;
}

.project-course {
    background-color: #f9f0f5;
    padding-top: 96px;
    padding-bottom: 96px;
}

.projects-course-top {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    flex-direction: column;
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
}

.projects-course-top-heading {
    color: #334155;
    margin-bottom: 16px;
    font-family: Kumbh Sans;
    font-size: 30px;
    font-weight: 700;
    line-height: 36px;
}

.projects-course-top-para {
    color: #64748b;
    font-family: Kumbh Sans;
    font-size: 16px;
    line-height: 28px;
}

.swiper-wrapper-container {
    width: 100%;
    margin: 0 auto;
}

.swiper-container {
    width: 100%;
}

.swiper-slide {
    display: flex;
    justify-content: center;
}

.project-course-project-01 {
    background-color: #fff;
    border: 1px solid #cbd5e1;
    border-radius: 16px;
    width: 100%;
    height: 265px;
    padding: 20px;
    box-sizing: border-box;
}

.project-course-project-01-title,
.project-course-project-02-title,
.project-course-project-03-title,
.project-course-project-04-title,
.project-course-project-05-title,
.project-course-project-06-title {
    border-radius: 16px;
    padding: 4px 12px;
    font-family: Kumbh Sans;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    display: inline-block;
}

.project-course-project-01-title {
    color: #6b21a8;
    background-color: #f3e8ff;
}

.project-course-project-02-title {
    color: #9d174d;
    background-color: #fce7f3;
}

.project-course-project-03-title {
    color: #fff;
    background-color: #461a62;
}

.project-course-project-04-title {
    color: #6b21a8;
    background-color: #f3e8ff;
}


.project-course-project-05-title {
    color: #9d174d;
    background-color: #fce7f3;
}

.project-course-project-06-title {
    color: #fff;
    background-color: #461a62;
}

.project-course-project-01-heading {
    margin-top: 8px;
    margin-bottom: 16px;
    font-family: Kumbh Sans;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
}

.project-course-project-01-para {
    color: #475569;
    font-family: Kumbh Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}

.project-group {
    display: flex;
    width: 100%;
}

.swiper-nav-btns {
    margin-top: 20px;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.left-nav-swiper-button,
.right-nav-swiper-button {
    background: none;
    border: none;
    color: #64748B;
    padding: 10px;
    cursor: pointer;
}

.custom-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 55px;
}

.progressbar-nav-item {

    display: flex;
    align-items: center;
    width: 20px;
    height: 5px;
    border-radius: 30%;
    background-color: #e2e8f0;
    margin: 0 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.progressbar-nav-item.active {
    background-color: #C76A97;
}

.job-opportunities-section {
    flex-direction: column;
    align-items: center;
    padding-top: 96px;
    display: flex;
    background-color: #f9f0f5;
}

.job-opportunities-main {
    max-width: 1200px;
    background-color: #f9f0f5;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 96px;
}


.job-oppurtunities-section-main {
    width: 100%;
    max-width: 1200px;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;

}

.job-opportunities-job-titles-main {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    flex-direction: column;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    width: 100%;
    display: flex;
}

.job-opportunities-left {
    width: 100%;
    max-width: 1200px;
}


.Job-opportunities-right {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* padding-top: 64px; */
    display: flex;
}

.Job-opportunities-right-image {
    width: 435px;
    padding-top: 60px;
}

.job-opportunities-left-main-text {
    color: #334155;
    font-family: Kumbh Sans;
    font-size: 30px;
    font-weight: 700;
    line-height: 36px;
}

.job-opportunities-left-info {
    color: #475569;
    margin-top: 16px;
    margin-bottom: 32px;
    font-family: Kumbh Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    width: 590px;
}

.job-opportunities-job-title-name {
    color: #c76a97;
    font-family: Kumbh Sans;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
}

.job-opportunities-job-titles {
    display: flex;
}

.job-opportunities-job-titles-main-01 {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    align-items: center;
    display: flex;
}

.job-opportunities-job-titles-left-tick-bg {
    background-color: #f6f5f6;
    border-radius: 50%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    display: flex;
}

.certificate-content-div-certificate-tick-bg {
    background-color: #F8F9FA;
    color: #c76a97;
    border-radius: 50%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    display: flex;
}

.job-opportunities-tick {
    color: #c76a97;
    font-size: 20px;
   
}



.batch-start-date {
    color: #475569;
    font-family: Kumbh Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}

.Book-free-demo {
    color: #fff;
    text-align: center;
    background-color: #461a62;
    border-radius: 12px;
    width: 196px;
    border: none;
    margin-right: 0;
    padding: 12px 32px;
    font-family: Kumbh Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-decoration: none;
    display: block;
    cursor: pointer;
}

.Book-free-demo-main {
    grid-column-gap: 32px;
    grid-row-gap: 32px;
    flex-direction: row;
    align-items: center;
    display: flex;
}

.batch-start-container-circle {
    background-color: #c76a97;
    border: 7.2px solid #dbeafe;
    border-radius: 50%;
    width: 24px;
    height: 24px;
}

.batch-start-container-date {
    color: #475569;
    font-family: Kumbh Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;

}

.batch-start-container-main {
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    flex-direction: column;
    justify-content: center;
    padding-top: 32px;
    display: flex;
}

.batch-start-container {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 32px;
    display: flex;
}

.certificate-main-container-div {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 96px;
    padding-bottom: 96px;
}

.certificate-container-div-inner-right {
    border-radius: 16px;
    align-items: center;
    width: 613px;
    margin-left: 25px;
    display: flex;
}

.certificate-container-div-inner-right-image {
    border-radius: 16px;
    overflow: hidden;
}

.certificate-container {
    background-color: #f9f0f5;
}

.certificate-main-container-div {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 80px;
    padding-bottom: 96px;
}

.certificate-container-div-inner {
    display: flex;
}


.get-certified-heading {
    color: #334155;
    margin-top: 0;
    margin-bottom: 0;
    font-family: Kumbh Sans;
    font-size: 40px;
    font-weight: 700;
    line-height: 40px;
}

.certificate-container-div-inner-left-description {
    color: #475569;
    width: 460px;
    margin-top: 24px;
    margin-bottom: 32px;
    font-family: Kumbh Sans;
    font-size: 18px;
    line-height: 28px;
}

.certificate-content-container {

    margin-bottom: 10px;
    display: flex;
    gap: 20px;
    align-items: center;
}

.certificate-content-div {
    color: #475569;
    font-family: Kumbh Sans;
    font-weight: 400;
    width: 485px;
}

.certificate-inner-icon {
    margin-left: 15px;
    width: 50px;
    height: 110px;
}

.certificate-content-txt {
    color: #334155;
    margin-bottom: 4px;
    font-family: Kumbh Sans;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
}

.certificate-content-image {
    width: 100px;
    height: 50px;
}

.certificate-bg {
    background-color: #461a62;
    height: 220px;
    width: 200px;
}

.hubspot-certificate {
    text-align: center;
    align-items: center;

}

.hubspotcertificate-txt {
    color: white;
    font-family: Kumbh Sans;
    margin-top: 10px;
}

.certification-section {
    display: flex;
    gap: 10px;
    max-width: 1200px;
    margin: auto;
    margin-bottom: 20px;
    background-color: #f9f0f5;
    display: flex;
    justify-content: space-evenly;
    /* padding:10px; */

}

.facebook-img {
    width: 200px;
}


@media (min-width: 320px) and (max-width: 767px) {
    .company-logo-images {
        width: 53px !important;
    }


    .companies-hired {
        width: 95%;
        padding-top: 0;
        padding-left: 16px;
        padding-right: 16px;
        display: block;
    }

    /* .certificate-bg{
display: block;
} */

    .certification-section {
        display: flex;
        flex-wrap: wrap;
    }


    .hiring-companies-sec {
        text-align: center;
        padding-top: 48px;
        padding-bottom: 48px;
        padding-left: 0;
    }

    .companies-hired-top-div {

        font-size: 26px;
    }



    .companies-hired-images {
        margin-top: 7px;
        margin-bottom: 32px;
        width: 100%;
        display: flex;
        margin-left: 0px;
    }

    .tools-section-bottom-div {
        max-width: 1200px;
        margin-top: 48px;
        margin-bottom: 32px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
    }

    .tools-image-individual {
        height: 115px;
        width: 100px;
        padding-left: 7px;
    }

    .project-group {
        display: flex;
        flex-wrap: wrap;
    }

    .project-course {
        background-color: #f9f0f5;
        padding-top: 96px;
        padding-bottom: 96px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .tools-section-heading {
        color: #1e293b;
        text-align: center;
        font-family: Kumbh Sans;
        font-size: 26px;
        font-weight: 600;
        line-height: 64px;
        padding: 5px;
    }

    .job-oppurtunities-section-main {
        width: 100%;
        max-width: 1200px;
        padding-top: 0;
        padding-bottom: 0;
        display: block;

    }

    .job-opportunities-left-info {
        color: #475569;
        margin-top: 16px;
        margin-bottom: 32px;
        font-family: "Inter";
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        width: 340px !important;
    }

    .job-opportunities-main {
        padding-left: 10px;
    }

    .Job-opportunities-right {
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .Job-opportunities-right-image {
        width: 300px !important;
    }

    .certificate-main-container-div {
        padding-left: 10px;
    }

    .certificate-container-div-inner-left-description {
        width: 375px;
    }

    .Book-free-demo-main {
        grid-column-gap: 32px;
        grid-row-gap: 32px;
        flex-direction: row;
        align-items: center;
        display: flex;
        justify-content: center;
    }

    .Book-free-demo {
        width: 300px;
        padding: 12px 32px;
        font-family: "Inter";
        font-size: 20px;
        font-weight: 600;
        line-height: 20px;
        text-decoration: none;
        display: block;
        cursor: pointer;
    }

    .certificate-container-div-inner-right {
        border-radius: 16px;
        align-items: center;
        width: 320px;
        margin-left: 0px !important;
        display: flex;
        overflow: hidden;
    }

    .certificate-container-div-inner {
        display: block;
    }

    .certificate-container-div-inner-left-description {
        width: 360px !important;
    }

    .certificate-content-container {
        display: flex;
        gap: 15px;
    }
}

@media (min-width:768px) and (max-width: 1024px) {
    .companies-hired {
        width: 95%;
        padding-left: 0px;
        padding-right: 0px;
    }

    .company-logo-images {
        width: 137px;
    }

    .tools-section-bottom-div {
        padding-left: 38px;
    }

    .projects-course-top-heading {
        padding: 10px;
    }

    .job-oppurtunities-section-main {
        padding-left: 15px;
    }

    .certificate-container-div-inner {
        display: block;
    }

    .job-opportunities-left-info {
        color: #475569;
        margin-top: 16px;
        margin-bottom: 32px;
        font-family: "Inter";
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        width: 400px;
    }

    .certificate-container-div-inner-left-description {
        color: #475569;
        width: 460px;
        margin-top: 24px;
        margin-bottom: 32px;
        font-family: "Inter";
        font-size: 18px;
        line-height: 28px;
        width: 600px;
    }

    .certificate-main-container-div {
        padding-left: 15px;
    }

    .Job-opportunities-right-image {
        width: 326px;
        padding-top: 94px;
    }
}