.digital-marketing-footer-main {
  background-color: #1e212d;
  padding: 70px 50px;
  display: flex;
  justify-content: center;
}

.digital-marketing-footer-inner {
  display: flex;
  max-width: 1140px;
}

.footer-left-ul-elements-div {
  display: flex;
}

.footer-left-ul-elements {
  list-style: none;
  padding: 0;
  margin: 0;
  color: #ffff;
}

.footer-left-ul-elements li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.footer-icon-phone {
  margin-right: 10px;
  font-size: 20px;
  font-family: "Kumbh Sans";
}

.footer-icon-map {
  margin-right: 10px;
  color: #ffff;
  /* font-size: 30px !important; */
}

.footer-email {
  display: flex;
}

.em-icon {
  color: #ffff;

}

.footer-icon-clock {
  margin-right: 10px;
  color: #ffff;
  font-family: "Kumbh Sans";
  font-size: 20px;
}

.footer-left-ul-elements span {
  display: inline-block;
}

.footer-1 {
  padding: 10px 30px 10px 30px;
  line-height: 25px;
}

.footer-2 {
  padding: 10px 30px 10px 30px;
  line-height: 25px;
}

.footer-3 {
  color: #ffff;
  font-family: "Kumbh Sans";
  padding: 10px 30px 10px 30px;
  line-height: 25px;
}

.footer-heading-links-li a {
  cursor: pointer;
  text-decoration: none;
  color: #ffff;
}

.footer-heading-links-li a:hover {
  color: #c76a97;
}

.footer-heading {
  font-size: 18px;
  font-weight: 600;
  font-family: "Kumbh Sans";
  color: #ffff;
}

.footer-para {
  font-size: 16px;
  font-family: "Kumbh Sans";
  color: #ffff;
}

.footer-li-span {
  font-size: 14px;
  font-family: "Kumbh Sans";
}

.footer-heading-links-ul {
  font-size: 18px;
  font-family: "Kumbh Sans";
}

.iframe-map {
  width: 100%;
  height: 300px;
  margin-top: 10px;
}

.footer-logos {
  display: flex;
  gap: 20px !important;
  justify-content: center;
}

.footer-left {
  display: flex;
  width: 100%;
}

.footer-right {
  display: flex;
  width: 100%;
}

.register-div {
  background-color: #461a62;
  display: flex;
  justify-content: space-evenly;
  gap: 220px;
  align-items: center;
  padding: 70px 0px;
}

.register-for-demo {
  color: #ffff;
  font-size: 24px;
  font-family: "Kumbh Sans";
  font-weight: 700;
  margin-right: 10px;
}

.request-for-demo-btn {
  background-color: #c76a97;
  padding: 10px 30px;
  border-radius: 10px;
  border: none !important;
  font-size: 18px;
  font-family: "Kumbh Sans";
  font-weight: 600;
  color: #ffff;
}

.register-btn {
  text-decoration: none !important;
  color: #ffff !important;
}

.register-btn:hover {
  color: #ffff !important;
}

.city-container-title {
  color: #000000;
  font-size: 19px;
  font-family: "Kumbh Sans";
}

.city-container {
  padding: 70px 50px 70px 135px;
}

.city-links-div {
  font-size: 18px;
  font-family: "Kumbh Sans";
  width: 90%;
}

.city-links {
  color: #c76a97;
  text-decoration: none !important;
  padding-left: 6px;
}

.wa-icon {
  margin-top: 5px;
  margin-right: 12px;
  transition: opacity .2s;
}

.wa-link {
  color: #ffff;
  font-family: "Kumbh Sans";
  font-size: 16px;
  line-height: 24px;
  align-items: center;
}

.inline-div {
  max-width: 100%;
  display: inline-block;
  text-decoration: none;
}

.block-div-2 {
  /* margin-top: 24px; */
  margin-bottom: 0;
}

.wa-btn {
  align-items: center;
  text-decoration: none !important;
  display: flex;
  color: #ffff !important;
}

.gmap{
  width: 600px;
  height: 400px;
}

@media (min-width: 320px) and (max-width: 767px) {
  .digital-marketing-footer-inner {
    display: block;
    max-width: 1140px;
  }

  .footer-1 {
    line-height: 25px;
  }

  .gmap{
    width: 100%
  }
  
  .footer-2 {
    line-height: 25px;
  }

  .footer-3 {
    color: #ffff;
    padding: 10px;
    line-height: 25px;
  }

  .digital-marketing-footer-main {
    background-color: #1e212d;
    padding: 35px 2px;
    display: flex;
    justify-content: center;
  }

  .register-div {
    background-color: #1e212d;
    display: block;
    justify-content: space-evenly;
    align-items: center;
    padding: 30px 20px;
    text-align: center;
  }

  .city-container {
    padding: 20px;
  }

  .city-links {
    padding: 2px;
  }

  .register-for-demo {
    color: #ffff;
    font-size: 20px;
    font-weight: 700;
    margin-top: 10px;
  }

  .request-for-demo-btn {
    margin-top: 20px;
    padding: 10px 60px;
  }

  .footer-left {
    display: block;
  }

  .footer-right {
    display: flex;
  }

  .footer-left-ul-elements li {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .footer-logos {
    display: flex;
    justify-content: start;
    margin-left: 20px;
  }

  .footer-icons {
    font-size: 35px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .footer-3 {
    color: #ffff;
    padding: 2px;
  }

  .register-div {
    display: flex;
    gap: 0px;
  }

  .city-container {
    padding: 20px 20px 26px 38px;
  }

  .city-links-div {
    width: 100%;
  }

  .digital-marketing-footer-main {
    padding: 10px 10px;
    display: flex;
    justify-content: start;
  }
  .digital-marketing-footer-inner{
display: flex;
flex-direction: column;

}
}